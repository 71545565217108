<template>
  <div class="ringchat" style="padding-top: 10%">
    <dv-active-ring-chart :config="config" style="width: 5rem; height: 20vh" />
    <div class="chart">
      <div>本月新增：</div>
      <div style="color: #e8c253">{{ list.month_num * multiplenumber }}</div>
      <div style="margin-top: 10%;">订单总数：</div>
      <div style="color: #34d8e4">
        {{ list.total_order_num * multiplenumber }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HomeView",
  components: {},
  props: {
    multiplenumber: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      list: {},
      config: {
        // data: [
        //   {
        //     name: "支付宝",
        //     value: 0,
        //   },
        //   {
        //     name: "微信",
        //     value: 0,
        //   },
        // ],
      },
    };
  },
  mounted() {
    this.getList();
    setInterval(() => {
      this.getList();
    }, 60000);
  },
  beforeDestroy() {
    // 组件销毁前清除定时器
    clearInterval(this.startGetList);
  },
  methods: {
    startGetList() {
      // 每分钟执行一次getList
      this.listInterval = setInterval(this.getList, 60000);
    },
    async getList() {
      this.axios({
        method: "get",
        url: "/api/LargeScreenData/getTotalOrderNum",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((res) => {
          if (res.data.code == 0) {
            this.list = res.data.data;
            this.config = {
              data: [
                {
                  name: "支付宝",
                  value: res.data.data.ali_num * this.multiplenumber,
                },
                {
                  name: "微信",
                  value: res.data.data.wx_num * this.multiplenumber,
                },
              ],
            };
            this.config = { ...this.config };
          }
        })
        .catch((error) => {
          console.log("error", error); //  错误处理
        });
    },
  },
};
</script>
<style lang="less">
.ringchat {
  // padding:5%;
  height: 25vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.chart {
  font-weight: bolder;
  line-height: 3vh;
  text-align: start;
  padding: 2%;
  margin: 5%;
  // height: 12vh;
  width: 200px;
  font-size: 25px;
  background-color: #081653;
  border: 5px solid #061A70;
}
</style>
