<template>
  <div style="padding-top: 10%; ">
    <dv-scroll-board
      class="center-text"
      :config="config"
      style="width: 90%; height: 32vh;"
    />
  </div>
</template>
<script>
export default {
  name: "HomeView",
  components: {},
  props: {
    multiplenumber: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      list: [],
      config: {
        header: ["时间", "渠道", "金额(元)", "商户"],
        headerBGC: "#000066",
        oddRowBGC: "#000066",
        evenRowBGC: "#102262",
        data: [],
      },
    };
  },
  mounted() {
    this.getList();
    this.listInterval = setInterval(() => {
      this.getList();
    }, 60000);
  },
  beforeDestroy() {
    // 组件销毁前清除定时器
    clearInterval(this.listInterval);
  },
  methods: {
    async getList() {
      this.axios({
        method: "get",
        url: "/api/LargeScreenData/getNewestOrderData",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((res) => {
          if (res.data.code == 0) {
            this.list = [];
            res.data.data.map((item) => {
              var a = [
                item.charge_end,
                item.mini_type,
                item.money_paid,
                item.store_name,
              ];
              this.list.push(a);
            });
            this.config.data = this.list;
            this.config = { ...this.config };
          }
        })
        .catch((error) => {
          console.log("error", error); //  错误处理
        });
    },
  },
};
</script>
<!-- api/LargeScreenData/getNewestOrderData -->
<style>
.center-text {
  padding: 5%;
}
</style>
