<template>
  <div class="waterchat">
    <div>
      <dv-water-level-pond :config="config1" style="width: 12rem; height: 6vh" />
      销售额
    </div>
    <div>
      <dv-water-level-pond :config="config2" style="width: 12rem; height: 6vh" />
      同比上月
    </div>
    <div>
      <dv-water-level-pond :config="config3" style="width: 12rem; height: 6vh" />
      进度
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  components: {},
  data() {
    return {
      config1: {
        data: [],
        shape: "round",
        colors:['#d7d746', '#3DE7C9']
      },
      config2: {
        data: [],
        shape: "round",
        colors:['#0194fc', '#3DE7C9']
      },
      config3: {
        data: [55],
        shape: "round",
      },
    };
  },
};
</script>
<style lang="less">
.waterchat {
  padding-top: 13%;
  display: flex;
  align-items:center;
  justify-content: space-around;
}
</style>
