<template>
  <dv-full-screen-container class="fullscreen">
    <div class="home">
      <div class="homebackground">
        <div class="homebackground-round"></div>
        <div class="homebackground-effect1"></div>
        <div class="homebackground-effect2"></div>
      </div>

      <div class="boxtop">
        <dv-decoration-8 style="width: 5rem; height: 1rem" />
        <div class="cell">
          <div>
            <div class="title">央电总部实时大数据</div>
            <dv-decoration-5 style="width: 5rem; height: 1rem" />
          </div>
        </div>
        <div class="timeCss"> {{ today }}</div>
        <dv-decoration-8 :reverse="true" style="width: 5rem; height: 1rem" >
          </dv-decoration-8>
      </div>
      <div class="box">
        <div class="box1">
          <div class="box1-devicebox">
            <dv-border-box-11 title="设备数据"   >
              <div class="box1-deviceboxp">
                <div class="box1-deviceboximg">
                  <img
                    src="../assets/device1.png"
                    mode="widthFix"
                  />
                  <div class="box1-deviceboximgfont">
                    <span>设备数</span>
                    <span class="font-blue" style="font-size: 30px;">{{
                     ( device.totalNumDevice * this.multiplenumber).toFixed(0)
                    }}</span>
                  </div>
                </div>
                <div class="box1-deviceboximg">
                  <img
                    src="../assets/device2.png"
                    mode="widthFix"
                  />
                  <div class="box1-deviceboximgfont">
                    <span>在线数</span>
                    <span class="font-green" style="font-size: 30px;">{{
                      (device.onlineDeviceNum * this.multiplenumber).toFixed(0)
                    }}</span>
                  </div>
                </div>
              </div>
            </dv-border-box-11>
          </div>
          <div class="box1-scrollchat">
            <dv-border-box-11 title="订单监控" class="box1-scrollchatp">
              <ScrollboardChat1
                :multiplenumber="multiplenumber"
              ></ScrollboardChat1>
            </dv-border-box-11>
          </div>
          <div>
            <dv-border-box-11 title="订单累计租借人数">
              <RingChat :multiplenumber="multiplenumber"> </RingChat>
            </dv-border-box-11>
          </div>
        </div>
        <div class="box2">
          <div>
            <FlyChat></FlyChat>
          </div>
          <div class="box2list">
            <dv-border-box-11 title="商户列表">
              <ScrollboardChat2
                :multiplenumber="multiplenumber"
              ></ScrollboardChat2>
            </dv-border-box-11>
          </div>
        </div>
        <div class="box3">
          <div class="box3-Summarybox">
            <dv-border-box-11 title="交易汇总">
              <Summary :multiplenumber="multiplenumber"></Summary>
            </dv-border-box-11>
          </div>
          <div class="box3-lineboxline">
            <dv-border-box-11 title="数据统计">
              <LineChart :multiplenumber="multiplenumber"></LineChart>
            </dv-border-box-11>
          </div>
          <div class="box3-box">
            <dv-border-box-11 title="代理商" class="box3-scrollchat">
              <ScrollboardChat3
                :multiplenumber="multiplenumber"
              ></ScrollboardChat3>
            </dv-border-box-11>
          </div>
        </div>
      </div>
    </div>
  </dv-full-screen-container>
</template>

<script>
import FlyChat from "../components/FlyChat";
import HelloWorld from "@/components/HelloWorld.vue";
import RingChat from "../components/RingChat";
import ScrollboardChat1 from "../components/ScrollboardChat1";
import ScrollboardChat2 from "../components/ScrollboardChat2";
import ScrollboardChat3 from "../components/ScrollboardChat3";
import BarChart from "../components/BarChart";
import LineChart from "../components/LineChart";
import NumberChart from "../components/NumberChart";
import WaterChat from "../components/WaterChat";
import Summary from "../components/Summary";
import dayjs from "dayjs";

export default {
  name: "HomeView",
  components: {
    LineChart,
    BarChart,
    HelloWorld,
    RingChat,
    FlyChat,
    ScrollboardChat1,
    ScrollboardChat2,
    ScrollboardChat3,
    NumberChart,
    WaterChat,
    Summary,
  },
  data() {
    return {
      // loginform: {},
      device: {},
      multiplenumber: 1,
      today:null
    };
  },
  mounted() {
    this.today = dayjs(new Date()).format('YYYY-MM-DD')
    console.log(this.today)
    this.getDevice();
    this.getList();
    this.startGetList();
  },
  methods: {
    async getDevice() {
      this.axios({
        method: "get",
        url: "/api/LargeScreenData/getDeviceNum",
        headers: {
          // "Access Control Allow Origin":*,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((res) => {
          if (res.data.code == 0) {
            this.device = res.data.data;
          }
        })
        .catch((error) => {
          console.log("error", error); //  错误处理 相当于error
          this.$message.error("登录失败");
        });
    },
    beforeDestroy() {
      // 组件销毁前清除定时器
      clearInterval(this.listInterval1);
      clearInterval(this.listInterval2);
    },
    startGetList() {
      // 每分钟执行一次getList
      this.listInterval1 = setInterval(this.getDevice, 60000);
      this.listInterval2 = setInterval(this.getList, 60000);
    },
    async getList() {
      this.axios({
        method: "get",
        url: "/api/LargeScreenData/getLargeScreenMultiple",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((res) => {
          if (res.data.code == 0) {
            this.multiplenumber = res.data.data.large_screen_multiple;
          }
        })
        .catch((error) => {
          console.log("error", error); //  错误处理 相当于error
          this.$message.error("登录失败");
        });
    },
  },
};
</script>
<style lang="less">
.fullscreen {
  height: 100vh;
  margin: 0;
}
.home {
  position: relative;
  background-image: url("../assets/bg2.jpg");
  width: 100%;
  box-sizing: border-box;
  height: 100vh;
}
.boxtop {
  height: 10vh;
  width: 100%;
  display: flex;
  flex-dicretion: row;
  justify-content: space-between;
  align-items: center;
}
.box {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  width: 98%;
  height: 88vh;
  display: flex;
  flex-dicretion: column;
  justify-content: space-around;
  align-items: center;
}
.box1 {
  display: flex;
  flex-direction: column;
  width: 23%;
  height: 90vh;
  .box1-scrollchat {
    height: 48vh;
  }
  .box1-devicebox {
    height: 20vh;
    .box1-deviceboximg {
      flex: 1;
    }

    .box1-deviceboxp {
      display: flex;
      align-items: center;
      justify-content: space-around;
      height: 100%;
      padding: 5px;
    }
  }
}
.box2 {
  width: 55%;
  height: 90vh;
  .box2list {
    height: 30vh;
  }
}
.box3 {
  width: 23%;
  height: 90vh;
  text-align: center;
  .box3-scrollchat {
    height: 37vh;
  }
  .box3-lineboxline {
    height: 30vh;
  }
  .box3-Summarybox {
    height: 23vh;
  }
  .box3-Summarybox2 {
    height: 15vh;
  }
}
.homebackground {
  // position: relative;
  width: 100%;
  //height: 100vh;
}
.homebackground-round {
  width: 10rem;
  height: 10rem;
  position: absolute;
  // align-items:center;
  background-image: url("../assets/map.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.4;
}
.homebackground-effect1 {
  position: absolute;
  top: 45%;
  left: 50%;
  width: 12.86rem;
  height: 12.86rem;
  background-image: url("../assets/lbx.png");
  transform: translate(-50%, -50%);
  animation: rotate1 15s linear infinite;
}
.homebackground-effect2 {
  position: absolute;
  top: 45%;
  left: 50%;
  width: 11.32rem;
  height: 11.32rem;
  background-image: url("../assets/jt.png");
  transform: translate(-50%, -50%);
  animation: rotate2 10s linear infinite;
}
@keyframes rotate1 {
  form {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes rotate2 {
  form {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

.cell .title {
  margin-top: 20px;
  font-size: 30px;
  color: #61fcfe;
}

.font-blue {
  color: #61fcfe;
}
.font-green{
  color:#94FABD
}
.box1-deviceboximg{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
}
.box1-deviceboximg img{
  width:40%;
}
.box1-deviceboximgfont{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: large;
  font-weight: bolder;
}
.box1-scrollchatp{
  font-size: 30px;
}

.timeCss{
  position:absolute;
  font-size: 30px;
  font-weight: bolder;
  top: 0.5vh;
  left: 88vw;
  color: #61fcfe;
  // right: 95%;
}
</style>
