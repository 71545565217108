<template>
  <div class="content">
    <div ref="charts" style="width: 100%; height: 60vh; margin: 0 auto"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import china from "@/assets/china.json";
export default {
  data() {
    return {
      list2:[],
      points: [],
      // linesData: [// 线条数据
      //   { coords: [[116.407387, 39.904179], [87.628579, 43.793301]] },// 北京->新疆
      //   { coords: [[116.407387, 39.904179], [104.076452, 30.651696]] },// 北京->四川
      //   { coords: [[116.407387, 39.904179], [103.826777, 36.060634]] },// 北京->甘肃
      //   { coords: [[116.407387, 39.904179], [102.709372, 25.046432]] },// 北京->云南
      //   { coords: [[116.407387, 39.904179], [108.327537, 22.816659]] },// 北京->广西
      //   { coords: [[116.407387, 39.904179], [112.982951, 28.116007]] },// 北京->湖南
      //   { coords: [[116.407387, 39.904179], [117.020725, 36.670201]] },// 北京->山东
      //   { coords: [[116.407387, 39.904179], [113.753094, 34.767052]] },// 北京->河南
      //   { coords: [[116.407387, 39.904179], [112.578781, 37.813948]] },// 北京->山西
      //   { coords: [[116.407387, 39.904179], [119.296194, 26.101082]] },// 北京->福建
      //   { coords: [[116.407387, 39.904179], [120.152575, 30.266619]] },// 北京->浙江
      //   { coords: [[116.407387, 39.904179], [118.763563, 32.061377]] },// 北京->安徽
      //   { coords: [[116.407387, 39.904179], [113.266887, 23.133306]] },// 北京->广东
      //   { coords: [[116.407387, 39.904179], [108.953939, 34.266611]] }// 北京->陕西
      // ],
      // planePath: // 飞机svg
      //   'path://M1705.06,1318.313v-89.254l-319.9-221.799l0.073-208.063c0.521-84.662-26.629-121.796-63.961-121.491c-37.332-0.305-64.482,36.829-63.961,121.491l0.073,208.063l-319.9,221.799v89.254l330.343-157.288l12.238,241.308l-134.449,92.931l0.531,42.034l175.125-42.917l175.125,42.917l0.531-42.034l-134.449-92.931l12.238-241.308L1705.06,1318.313z',
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      this.axios({
        method: "get",
        url: "/api/LargeScreenData/getStorePosition",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((res) => {
          console.log('数据：',res);
          const maplist = [
            {
              aid:null,
              lat:31.197652,
              lng:121.119096,
              store_id:null,
              store_name:"中尖集团产业园"
            },
            {
              aid:null,
              lat:33.262041,
              lng:120.435388,
              store_id:null,
              store_name:"荷兰花海"
            },
            {
              aid:null,
              lat:30.222318,
              lng:120.034839,
              store_id:null,
              store_name:"中信大厦"
            },
            {
              aid:null,
              lat:24.275414,
              lng:116.10035,
              store_id:null,
              store_name:"清和园宾馆"
            },
            {
              aid:null,
              lat:38.533707,
              lng:115.031372,
              store_id:null,
              store_name:"九华山景区"
            },
            {
              aid:null,
              lat:34.352843,
              lng:107.384489,
              store_id:null,
              store_name:"墨宝文渊"
            },
            {
              aid:null,
              lat:29.556634,
              lng:114.303144,
              store_id:null,
              store_name:"大福源鹤"
            },
            {
              aid:null,
              lat:27.897282,
              lng:112.535633,
              store_id:null,
              store_name:"九八一号"
            },
            {
              aid:null,
              lat:24.500589,
              lng:118.140860,
              store_id:null,
              store_name:"桂冠壹号院"
            },
            {
              aid:null,
              lat:39.854007,
              lng:116.285779,
              store_id:null,
              store_name:"金隅大厦"
            },
            {
              aid:null,
              lat:39.079082,
              lng:117.1985,
              store_id:null,
              store_name:"五洲医院"
            },
            {
              aid:null,
              lat:34.64783,
              lng:119.28165,
              store_id:null,
              store_name:"花果山风景区"
            },
            {
              aid:null,
              lat:114.09738,
              lng:114.09738,
              store_id:null,
              store_name:"羊山万达广场"
            },
            {
              aid:null,
              lat:31.239508,
              lng:121.5024,
              store_id:null,
              store_name:"陆家嘴中国平安金融大厦"
            },
            {
              aid:null,
              lat:31.192947,
              lng:121.439674,
              store_id:null,
              store_name:"美罗城"
            },
            {
              aid:null,
              lat:32.017185,
              lng:118.784355,
              store_id:null,
              store_name:"南京市第一医院"
            },
            {
              aid:null,
              lat:31.39178,
              lng:120.975204,
              store_id:null,
              store_name:"吸引力广场"
            },
          ]
          console.log(" res.data.data", res.data.data);
          const mergedArray = maplist.concat(res.data.data);
          if (res.data.code == 0) {
            this.list = mergedArray;
            mergedArray.map((item) => {
              var value = [item.lng, item.lat];
              var points = {
                value: value,
                itemStyle: { color: "#4682B4" },
              };
              this.points.push(points)
            });
            console.log("mergedArray", mergedArray);
            console.log("this.points", this.points);
            this.initCharts();
          }
        })
        .catch((error) => {
          console.log("error", error); //  错误处理
        });
    },
    initCharts() {
      const charts = echarts.init(this.$refs["charts"]);
      const option = {
        layoutCenter: ["50%", "50%"],
        layoutSize: 700,
        backgroundColor: "rgba(255, 255, 255, 0)", // 背景颜色
        geo: {
          // 地图配置
          map: "china",
          label: {
            // 图形上的文本标签
            normal: {
              // 通常状态下的样式
              show: true,
              textStyle: {
                color: "#fff",
              },
            },
            emphasis: {
              // 鼠标放上去高亮的样式
              textStyle: {
                color: "#fff",
              },
            },
          },
          itemStyle: {
            // 地图区域的样式设置
            normal: {
              // 通常状态下的样式
              borderColor: "#4169E1",
              borderWidth: 1,
              areaColor: {
                //地图区域的颜色
                type: "radial", // 径向渐变
                x: 0.5, // 圆心
                y: 0.5, // 圆心
                r: 0.8, // 半径
                colorStops: [
                  {
                    // 0% 处的颜色
                    offset: 0,
                    color: "rgb(65 ,105 ,225)",
                  },
                  {
                    // 100% 处的颜色
                    offset: 1,
                    color: "rgba(0, 102, 154, .4)",
                  },
                ],
              },
            },
            // 鼠标放上去高亮的样式
            emphasis: {
              areaColor: "#2386AD",
              borderWidth: 0,
            },
          },
        },
        series: [
          {
            // 散点系列数据
            type: "effectScatter", // 带有涟漪特效动画的散点（气泡）图
            coordinateSystem: "geo", //该系列使用的坐标系:地理坐标系
            // 特效类型,目前只支持涟漪特效'ripple'，意为“涟漪”
            effectType: "ripple",
            // 配置何时显示特效。可选'render'和'emphasis' 。
            showEffectOn: "render",
            rippleEffect: {
              // 涟漪特效相关配置。
              period: 10, // 动画的周期，秒数。
              scale: 4, // 动画中波纹的最大缩放比例。
              // 波纹的绘制方式，可选 'stroke' 和 'fill'。
              brushType: "fill",
            },
            zlevel: 1, // 所有图形的 zlevel 值。
            data: this.points,
          },
          {
            // 线条系列数据
            type: "lines",
            zlevel: 2,
            symbol: ["none", "arrow"], // 标记的图形: 箭头
            symbolSize: 10, // 标记的大小
            effect: {
              // 线条特效的配置
              show: true,
              period: 6, // 特效动画的时间，单位s
              trailLength: 0, // 特效尾迹的长度。取值[0,1]值越大，尾迹越重
              symbol: this.planePath, // 特效图形的标记 可选'circle'等
              symbolSize: 15, // 特效标记的大小
            },
            lineStyle: {
              // 线条样式
              normal: {
                color: "#93EBF8",
                width: 2.5, // 线条宽度
                opacity: 0.6, // 尾迹线条透明度
                curveness: 0.2, // 尾迹线条曲直度
              },
            },
            data: this.linesData,
          },
        ],
      };
      // 地图注册，第一个参数的名字必须和option.geo.map一致
      echarts.registerMap("china", china);
      charts.setOption(option);
    },
  },
};
</script>
<style scoped>
/* .content {
  background-color: #0e2152;
  height: 100%;
} */
</style>
