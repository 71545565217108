<template>
  <div style="padding-top: 15%" class="centertext">
    <dv-scroll-board
      class="center-text3"
      :config="config"
      style="width: 90%; height: 30vh"
    />
  </div>
</template>
<script>
export default {
  name: "HomeView",
  components: {},
  props: {
    multiplenumber: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      config: {
        header: ["序号", "代理名称", "设备数", "交易额"],
        headerBGC: "#000066",
        oddRowBGC: "#000066",
        evenRowBGC: "#102262",
        data: [],
      },
    };
  },
  mounted() {
    this.getList();
    this.listInterval = setInterval(() => {
      this.getList();
    }, 60000);
  },
  beforeDestroy() {
    // 组件销毁前清除定时器
    clearInterval(this.listInterval);
  },
  methods: {
    async getList() {
      this.axios({
        method: "get",
        url: "/api/LargeScreenData/getAgentRanking",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((res) => {
          if (res.data.code == 0) {
            this.list = [];
            res.data.data.map((item) => {
              var a = [
                item.sort,
                item.name,
                (item.device_num * this.multiplenumber).toFixed(0),
                (item.transaction_amount * this.multiplenumber).toFixed(2),
              ];
              this.list.push(a);
            });
            this.config.data = this.list;
            this.config = { ...this.config };
          }
        })
        .catch((error) => {
          console.log("error", error); //  错误处理
        });
    },
  },
};
</script>

<style>
.centertext {
  display: flex;
  justify-content: center;
  text-align: center;
}
</style>
