import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view'
import * as echarts from 'echarts';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import 'lib-flexible/flexible'
import 'lib-flexible'
Vue.use(dataV)
Vue.use(axios);
Vue.use(ElementUI);

axios.defaults.baseURL ='https://api.iyangdian.com/'
Vue.prototype.axios = axios
Vue.config.productionTip = false
Vue.prototype.$echarts = echarts
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
