<template>
  <div class="">
    <div id="main" style="width: 600px; height: 30vh"></div>
  </div>
</template>
<script>
import * as echarts from "echarts";
export default {
  name: "",
  components: {},
  props: {
    multiplenumber: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      config: {
        data: [],
      },
    };
  },
  mounted() {
    // 基于准备好的dom，初始化echarts实例
    var myChart = echarts.init(document.getElementById("main"));
    // 绘制图表
    myChart.setOption({
      title: {
        text: "30日订单:1008420",
      },
      tooltip: {},
      xAxis: {
        data: ["衬衫", "羊毛衫", "雪纺衫", "裤子", "高跟鞋", "袜子"],
      },
      yAxis: {},
      series: [
        {
          data: [120, 200, 150, 80, 70, 110, 130],
          type: "bar",
          itemStyle: {
            // 设置柱状图的颜色
            color: "#3399ff",
          },
        },
      ],
    });
  },
};
</script>
