<template>
  <div class="centertext">
    <div id="main2" style="width: 6rem; height: 22vh; margin-top: 14%"></div>
  </div>
</template>
<script>
import * as echarts from "echarts";
export default {
  name: "",
  components: {},
  props: {
    multiplenumber: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      config: {
        data: [],
      },
    };
  },
  mounted() {
    this.getRecentSevenDays();
    this.getList();
  },
  beforeDestroy() {
    // 组件销毁前清除定时器
    clearInterval(this.startGetList);
  },
  methods: {
    startGetList() {
      // 每分钟执行一次getList
      this.listInterval = setInterval(this.getList, 60000);
    },
    multiplyArrayElementsByN(arr, n) {
      // 使用map方法遍历数组，并返回一个新的数组
      return arr.map((item) => {
        // 尝试将当前元素转换为数字
        const num = Number(item);
        // 检查转换后的数字是否是NaN（即原元素不是数字类型且不能转换为数字）
        if (isNaN(num)) {
          // 如果原元素不能转换为数字，可以选择保持原样或采取其他措施
          // 这里我们选择保持原样
          return item;
        } else {
          // 如果可以转换为数字，则翻n倍
          return num * n;
        }
      });
    },
    getRecentSevenDays() {
      var today = new Date(); // 获取当前日期
      var dates = []; // 存储日期数组
      for (var i = 6; i >= 0; i--) {
        var tempDate = new Date(today); // 创建临时日期对象
        tempDate.setDate(tempDate.getDate() - i); // 获取七天内的日期
        var month = tempDate.getMonth() + 1; // 月份加1，getMonth()方法返回的月份是从0开始计数的
        var day = tempDate.getDate(); // 获取日期
        var dateStr =
          tempDate.getFullYear() +
          "-" +
          (month < 10 ? "0" + month : month) +
          "-" +
          (day < 10 ? "0" + day : day); // 格式化日期字符串
        dates.push(dateStr); // 将日期字符串添加到数组中
      }
      this.dates = dates; // 返回日期数组
    },

    async getList() {
      this.axios({
        method: "get",
        url: "/api/LargeScreenData/getSevenDaysData",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((res) => {
          if (res.data.code == 0) {
            var seven_order_num = this.multiplyArrayElementsByN(res.data.data.seven_order_num, this.multiplenumber);
            var seven_order_turnover = this.multiplyArrayElementsByN(res.data.data.seven_order_turnover, this.multiplenumber);
            var seven_user_num = this.multiplyArrayElementsByN(res.data.data.seven_user_num, this.multiplenumber);
            // 基于准备好的dom，初始化echarts实例
            var myChart = echarts.init(document.getElementById("main2"));
            var series = [
              {
                name: "交易额",
                type: "line",
                stack: "Total",
                data: seven_order_turnover,
              },
              {
                name: "订单数",
                type: "line",
                stack: "Total",
                data: seven_order_num,
              },
              {
                name: "用户注册量",
                type: "line",
                stack: "Total",
                data: seven_user_num,
              },
            ];
            // 绘制图表
            myChart.setOption({
              title: {},
              tooltip: {
                trigger: "axis",
              },
              legend: {
                textStyle: {
                  color: "#F1F1F3", // 重点是这里
                  fontSize: 14,
                },
                // data: [
                //   "交易额",
                //   "订单数 ",
                //   "用户注册量",
                // ],
              },
              grid: {
                left: "8%",
                right: "9%",
                bottom: "0%",
                containLabel: true,
              },
              xAxis: {
                type: "category",
                boundaryGap: false,
                data: this.dates,
                axisLine: {
                  lineStyle: {
                    color: "#ffff",
                  },
                },
              },
              yAxis: {
                type: "value",
                axisLine: {
                  lineStyle: {
                    color: "#ffff",
                  },
                },
              },
              series: series,
            });
          }
        })
        .catch((error) => {
          console.log("error", error); //  错误处理
        });
    },
  },
};
</script>
<style>
.centertext {
  height: 30vh;
  display: flex;
  justify-content: center;
  text-align: center;
}
</style>
