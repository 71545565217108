<template>
  <div class="box3-Summarybox">
    <div class="box3-Summarybox2">
      <div class="Summarybtitle">
        <div
          :class="
            currentSwitch == 1 ? 'buttonstyle1' : 'box3-Summarybox-buttonp'
          "
        >
          日汇总
        </div>
        <div
          :class="
            currentSwitch == 2 ? 'buttonstyle1' : 'box3-Summarybox-buttonp'
          "
        >
          月汇总
        </div>
        <div
          :class="
            currentSwitch == 3 ? 'buttonstyle1' : 'box3-Summarybox-buttonp'
          "
        >
          年汇总
        </div>
        <div
          :class="
            currentSwitch == 4 ? 'buttonstyle1' : 'box3-Summarybox-buttonp'
          "
        >
          累计汇总
        </div>
      </div>
      <div class="summarybox-title">
        <div class="Summarybox">
          <div style="align-items: center">订单量:</div>
          <dv-digital-flop
            :config="config"
            style=" height: 5vh;"
          />
        </div>
        <div class="Summarybox">
          <div style="align-items: center">交易流水:</div>
          <dv-digital-flop
            :config="config2"
            style=" height: 5vh;"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
export default {
  name: "",
  components: {},
  props: {
    multiplenumber: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      // 当前switch的值，用于决定显示哪种汇总
      currentSwitch: 1,
      list: {},
      day: 1,
      config: {
        number: [], //初始数据
        content: "{nt}",
        style: {
          //这里可以修改默认样式
          fontSize: 25, //字体大小
          fill: "#FFFFFF", //字体颜色
        },
      },
      config2: {
        number: [], //初始数据
        content: "{nt}",
        style: {
          //这里可以修改默认样式
          fontSize: 25, //字体大小
          fill: "#FFFFFF", //字体颜色
        },
      },
    };
  },
  mounted() {
    this.getList();
    this.startGetList();
    this.startSwitchUpdater();
  },
  beforeDestroy() {
    // 组件销毁前清除定时器
    clearInterval(this.listInterval);
    clearInterval(this.startSwitchUpdater);
  },
  methods: {
    startGetList() {
      // 每分钟执行一次getList
      this.listInterval = setInterval(this.getList, 60000);
    },
    // 更新currentSwitch的定时器
    startSwitchUpdater() {
      this.switchInterval = setInterval(() => {
        // 更新currentSwitch的值，并循环从1到4
        this.currentSwitch = (this.currentSwitch % 4) + 1;
        // 更新显示的数据
        this.getSummary(this.currentSwitch);
      }, 10000); // 每5秒执行一次
    },
    getList() {
      this.axios({
        method: "get",
        url: "/api/LargeScreenData/getTransactionSummary",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((res) => {
          if (res.data.code == 0) {
            console.log("res:", res);
            this.list = res.data.data;
          }
        })
        .catch((error) => {
          console.log("error", error); //  错误处理
        });
    },
    getSummary(i) {
      // this.config.number[0] = Math.floor(Math.random() * n + 1);
      // this.config = { ...this.config }; //对象解构，更新props
      // this.config2.number[0] = Math.floor(Math.random() * n + 1);
      // this.config2 = { ...this.config2 }; //对象解构，更新props
      // this.currentSummaryType =
      //   this.summaryTypes[this.summaryIndex % this.summaryTypes.length];
      switch (i) {
        case 1:
          this.config.number[0] = Number(
            this.list.today.order_sum * this.multiplenumber
          );
          this.config2.number[0] = Number(
            this.list.today.turnover_sum * this.multiplenumber
          );
          this.config = { ...this.config };
          this.config2 = { ...this.config2 }; //对象解构，更新props
          break;
        case 2:
          this.config.number[0] = Number(
            this.list.month.order_sum * this.multiplenumber
          );
          this.config2.number[0] = Number(
            this.list.month.turnover_sum * this.multiplenumber
          );
          this.config = { ...this.config };
          this.config2 = { ...this.config2 }; //对象解构，更新props
          break;
        case 3:
          this.config.number[0] = Number(
            this.list.year.order_sum * this.multiplenumber
          );
          this.config2.number[0] = Number(
            this.list.year.turnover_sum * this.multiplenumber
          );
          this.config = { ...this.config };
          this.config2 = { ...this.config2 }; //对象解构，更新props
          break;
        case 4:
          this.config.number[0] = Number(
            this.list.total.order_sum * this.multiplenumber
          );
          this.config2.number[0] = Number(
            this.list.total.turnover_sum * this.multiplenumber
          );
          this.config = { ...this.config };
          this.config2 = { ...this.config2 }; //对象解构，更新props
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style>
.box3-Summarybox {
  width: 100%;
  height: 15vh;

  display: flex;
  justify-content: space-around;
  text-align: center;
}
.box3-Summarybox2 {
  padding-top: 15%;
  width: 100%;
}
.Summarybtitle {
  display: flex;
  flex-dicretion: row;
  /* flex: 1; */
  padding: 0 10px;
  justify-content: space-around;
  text-align: center;
}
.Summarybtitle div {
  width: 20%;
  height: 3vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.box3-Summarybox-buttonp {
  margin: 0.083rem;
  padding: 0.083rem;
  font-size: 20px;
  border: 1px solid #091a62;
  border-radius: 0.25rem;
  /* background: radial-gradient(circle, #ff99cc 0%, #33ccff 100%) */
  background: radial-gradient(circle, #091a62 11%, #091a62 100%);
}
.buttonstyle1 {
  margin: 0.083rem;
  padding: 0.083rem;
  font-size: 20px;
  color: #091a62;
  border: 1px solid #06f2ff;
  border-radius: 0.25rem;
  background: radial-gradient(circle, #06f2ff 1%, #06f2ff 100%);
}
/* .buttonstyle2{
  background: radial-gradient(circle, #ff99cc 6%, #33ccff 100%)
} */
.Summarybox {
  display: flex;
  width: 40%;
  border-radius: 15px;
  padding: 5px;
  font-size: 20px;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  background: radial-gradient(circle, #0F1780 1%, #000099 100%);
}
.summarybox-title {
  display: flex;
  justify-content: space-around;
  margin-top: 2%;
  padding: 5px;
}
</style>
